import type { Skill } from './types/skill.type'
import type { Project } from './types/project.type'
import type { SocialMediaIcon } from './types/socialMediaIcons.type'
import type { NavLink } from './types/navLinkType'

export const skills: Skill[] = [
  {
    name: 'Vue.js',
    imageURL:
      'https://icon-icons.com/icons2/2107/PNG/64/file_type_vue_icon_130078.png',
    officialSiteURL: 'https://vuejs.org/',
  },
  {
    name: 'React.js',
    imageURL:
      'https://icon-icons.com/icons2/2107/PNG/64/file_type_reactjs_icon_130205.png',
    officialSiteURL: 'https://react.dev/',
  },
  {
    name: 'Angular',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/64/file_type_angular_icon_130754.png',
    officialSiteURL: 'https://angular.io/',
  },
  {
    name: 'Next.js',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2389/PNG/64/next_js_logo_icon_145038.png',
    officialSiteURL: 'https://nextjs.org/',
  },
  {
    name: 'Nuxt.js',
    imageURL: 'https://nuxt.com/assets/design-kit/icon-green.svg',
    officialSiteURL: 'https://nuxt.com/',
  },
  {
    name: 'GraphQL',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/64/file_type_graphql_icon_130564.png',
    officialSiteURL: 'https://graphql.org/',
  },
  {
    name: 'Redux',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2415/PNG/64/redux_original_logo_icon_146365.png',
    officialSiteURL: 'https://redux.js.org/',
  },
  {
    name: 'Pinia',
    imageURL: 'https://pinia.vuejs.org/logo.svg',
    officialSiteURL: 'https://pinia.vuejs.org/',
  },
  {
    name: 'Vuex',
    imageURL:
      'https://user-images.githubusercontent.com/7110136/29002857-9e802f08-7ab4-11e7-9c31-604b5d0d0c19.png',
    officialSiteURL: 'https://vuex.vuejs.org/',
  },
  {
    name: 'Node.js',
    imageURL:
      'https://icon-icons.com/icons2/2107/PNG/64/file_type_node_icon_130301.png',
    officialSiteURL: 'https://nodejs.org/en',
  },
  {
    name: 'Java',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2415/PNG/64/java_original_wordmark_logo_icon_146459.png',
    officialSiteURL: 'https://www.java.com/en/',
  },
  {
    name: 'Python',
    imageURL: 'https://cdn.icon-icons.com/icons2/112/PNG/64/python_18894.png',
    officialSiteURL: 'https://www.python.org/',
  },
  {
    name: 'PHP',
    imageURL: 'https://www.php.net//images/logos/new-php-logo.svg',
    officialSiteURL: 'https://www.php.net/',
  },
  {
    name: 'Express.js',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2699/PNG/64/expressjs_logo_icon_169185.png',
    officialSiteURL: 'https://expressjs.com/',
  },
  {
    name: 'Nest.js',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/64/file_type_nestjs_icon_130355.png',
    officialSiteURL: 'https://nestjs.com/',
  },
  {
    name: 'SpringBoot',
    imageURL:
      'https://cdn.icon-icons.com/icons2/3398/PNG/64/boot_spring_logo_icon_214693.png',
    officialSiteURL: 'https://spring.io/projects/spring-boot',
  },
  {
    name: 'FastAPI',
    imageURL:
      'https://cdn.icon-icons.com/icons2/3913/PNG/64/fastapi_logo_icon_248575.png',
    officialSiteURL: 'https://fastapi.tiangolo.com/',
  },
  {
    name: 'Flask',
    imageURL:
      'https://cdn.icon-icons.com/icons2/512/PNG/64/prog-flask_icon-icons.com_50797.png',
    officialSiteURL: 'https://flask.palletsprojects.com/en/3.0.x/',
  },
  {
    name: 'Laravel',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2108/PNG/512/laravel_icon_130892.png',
    officialSiteURL: 'https://laravel.com/',
  },
  {
    name: 'MongoDB',
    imageURL:
      'https://icon-icons.com/icons2/2415/PNG/64/mongodb_original_wordmark_logo_icon_146425.png',
    officialSiteURL: 'https://www.mongodb.com/',
  },
  {
    name: 'Firebase',
    imageURL:
      'https://cdn.icon-icons.com/icons2/691/PNG/64/google_firebase_icon-icons.com_61475.png',
    officialSiteURL: 'https://firebase.google.com/',
  },
  {
    name: 'PostgreSQL',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2415/PNG/64/postgresql_original_logo_icon_146391.png',
    officialSiteURL: 'https://www.postgresql.org/',
  },
  {
    name: 'MySQL',
    imageURL:
      'https://icon-icons.com/icons2/2415/PNG/64/mysql_original_wordmark_logo_icon_146417.png',
    officialSiteURL: 'https://www.mysql.com/',
  },
  {
    name: 'MSSQL',
    imageURL: 'https://cdn-icons-png.flaticon.com/512/5968/5968364.png',
    officialSiteURL: 'https://www.mysql.com/',
  },
  {
    name: 'TypeScript',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/64/file_type_typescript_official_icon_130107.png',
    officialSiteURL: 'https://www.typescriptlang.org/',
  },
  {
    name: 'Tailwind',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2699/PNG/64/tailwindcss_logo_icon_167923.png',
    officialSiteURL: 'https://tailwindcss.com/',
  },
  {
    name: 'SASS',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/64/file_type_sass_icon_130182.png',
    officialSiteURL: 'https://sass-lang.com/',
  },
  {
    name: 'Bootstrap',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2415/PNG/64/bootstrap_plain_logo_icon_146619.png',
    officialSiteURL: 'https://getbootstrap.com/',
  },
  {
    name: 'Strapi.io',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2389/PNG/64/strapi_logo_icon_144838.png',
    officialSiteURL: 'https://strapi.io/',
  },
  {
    name: 'WordPress',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2699/PNG/72/wordpress_logo_icon_167953.png',
    officialSiteURL: 'https://wordpress.com/',
  },
  {
    name: 'Vitest',
    imageURL:
      'https://user-images.githubusercontent.com/11247099/145112184-a9ff6727-661c-439d-9ada-963124a281f7.png',
    officialSiteURL: 'https://vitest.dev/',
  },
  {
    name: 'Swagger',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/64/file_type_swagger_icon_130134.png',
    officialSiteURL: 'https://swagger.io/',
  },
  {
    name: 'Docker',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2415/PNG/512/docker_original_wordmark_logo_icon_146557.png',
    officialSiteURL: 'https://www.docker.com/',
  },
  {
    name: 'JWT',
    imageURL: 'https://jwt.io/img/pic_logo.svg',
    officialSiteURL: 'https://jwt.io/',
  },
  {
    name: 'MUI',
    imageURL:
      'https://cdn.icon-icons.com/icons2/3913/PNG/64/mui_logo_icon_248416.png',
    officialSiteURL: 'https://mui.com/',
  },
  {
    name: 'Quasar',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/512/file_type_quasar_icon_130213.png',
    officialSiteURL: 'https://quasar.dev/',
  },
  {
    name: 'PrimeVue',
    imageURL:
      'https://i2.wp.com/www.primefaces.org/wp-content/uploads/2019/12/primevue-logo.png?fit=300%2C300&ssl=1',
    officialSiteURL: 'https://primevue.org/',
  },
  {
    name: 'daisyUI',
    imageURL:
      'https://img.daisyui.com/images/daisyui-logo/daisyui-logomark.svg',
    officialSiteURL: 'https://daisyui.com/',
  },
  {
    name: 'React-Bootstrap',
    imageURL: 'https://react-bootstrap.netlify.app/img/logo.svg',
    officialSiteURL: 'https://react-bootstrap.netlify.app/',
  },
  {
    name: 'BootstrapVue',
    imageURL: 'https://bootstrap-vue.org/_nuxt/icons/icon_512x512.67aef2.png',
    officialSiteURL: 'https://bootstrap-vue.org/',
  },
  {
    name: 'Elementor',
    imageURL:
      'https://cdn.icon-icons.com/icons2/3914/PNG/72/elementor_logo_icon_248955.png',
    officialSiteURL: 'https://elementor.com/',
  },
  {
    name: 'Unlimited3D',
    imageURL:
      'https://yt3.googleusercontent.com/c3CU7GaslnZK4gss4sgcwJdWzinb9GOuPSJu_ze4FYKJKyEenQJ2mLyBn2CkQc36yIfebjtFvQ=s160-c-k-c0x00ffffff-no-rj',
    officialSiteURL: 'https://unlimited3d.com/',
  },
  {
    name: 'Inertia.js',
    imageURL: 'https://avatars.githubusercontent.com/u/47703742?s=200&v=4',
    officialSiteURL: 'https://inertiajs.com/',
  },
  {
    name: 'Prisma',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/64/file_type_light_prisma_icon_130444.png',
    officialSiteURL: 'https://www.prisma.io/',
  },
  {
    name: 'nginx',
    imageURL:
      'https://cdn.icon-icons.com/icons2/2107/PNG/64/file_type_nginx_icon_130305.png',
    officialSiteURL: 'https://nginx.org/en/',
  },
]

export const projects: Project[] = [
  {
    name: 'Dockerized Nest.js, Prisma and PostgreSQL Blog Posts API with JWT Authentification 🔐',
    description:
      '🐋 Dockerized 😺 Nest.js, 🔷 Prisma, and 🐘PostgreSQL Blog Posts API with 🔐 JWT authentification , 📃posts and 👤 user management, 📑 pagination and 📜 Swagger documentation 🤗',
    projectURL:
      'https://github.com/NikolaJohnny94/nestjs-prisma-postgres-auth-api',
    imgURL: `${
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://nikola-ivanovic-software-engineer.com'
    }/images/projects/nestjs_prisma_blog_posts_api.jpg`,
    technologies: [
      'Nest.js',
      'Prisma',
      'PostgreSQL',
      'Swagger',
      'TypeScript',
      'JWT',
      'Docker',
      'nginx',
    ],
    showcase: 'github',
  },
  {
    name: 'Vue.js 3, Inertia.js, Laravel and PrimeVue Task Dashboard with Laravel Breeze Auth 🔐',
    description:
      'Task CRUD dashboard with client side pagination, searh and sorting, made with Vue.js 3, Inertia.js, Laravel, PrimeVue and Laravel Breeze.',
    projectURL: 'https://github.com/NikolaJohnny94/laravel-inertia-vue-crud',
    imgURL: `${
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://nikola-ivanovic-software-engineer.com'
    }/images/projects/vue_inertia_laravel_breeze_cover.jpg`,
    technologies: [
      'Vue.js',
      'Laravel',
      'PHP',
      'Inertia.js',
      'TypeScript',
      'PrimeVue',
      'Tailwind',
    ],
    showcase: 'github',
  },
  {
    name: 'Laravel CRUD API with 🔐 User Authentification, 🧪 Unit Tests, 🗄️ DB seeding and Swagger Documentation 🐘',
    description:
      'Laravel Tasks CRUD API with atuhentification, search functionality, DB seeding, unit tests and Swagger documentation. ',
    projectURL: 'https://github.com/NikolaJohnny94/laravel-api-auth',
    imgURL: `${
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://nikola-ivanovic-software-engineer.com'
    }/images/projects/laravel_api_auth.jpg`,
    technologies: ['Laravel', 'PHP', 'Swagger'],
    showcase: 'github',
  },
  {
    name: '3D Model Configurator created with Unlimited3D, React, TypeScript, Tailwind and Vite',
    description:
      '3D Model configurator for suitcase with body, handles, wheels and corners colors configurator, and animations like open/close, wheels spinner and extend/retract handle, created with Unlimited3D, React, TypeScript and Tailwind.',
    projectURL:
      'https://github.com/NikolaJohnny94/unlimited3d-react-typescript',
    imgURL: `${
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://nikola-ivanovic-software-engineer.com'
    }/images/projects/unlimited3d-react-typescript.jpg`,
    technologies: ['React.js', 'TypeScript', 'Tailwind', 'Unlimited3D'],
    showcase: 'github',
  },
  {
    name: 'PHP CRUD dashboard with daisyUI, Tailwind and MySQL',
    description:
      "User's dashboard with CRUD functionality created with PHP, daisyUI, Tailwind CSS and MySQL.",
    projectURL: 'https://github.com/NikolaJohnny94/php-crud-dashboard',
    imgURL: `${
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://nikola-ivanovic-software-engineer.com'
    }/images/projects/php_crud_cover.jpg`,
    technologies: ['PHP', 'daisyUI', 'Tailwind', 'MySQL'],
    showcase: 'github',
  },
  {
    name: "Next.js 14 and Tailwind CSS Flower Shop (Shopify's Fewor shop clone) [IN PROGRESS]",
    description:
      "Shopify's Fewor shop clone made with Next.js 14, TypeScript, Tailwind CSS and daisyUI. Project is stll in progress.",
    projectURL: 'https://nextjs-shopify-fewer-clone.netlify.app',
    imgURL: `${
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://nikola-ivanovic-software-engineer.com'
    }/images/projects/flower_shop_cover.jpg`,
    technologies: ['Next.js', 'React.js', 'TypeScript', 'Tailwind', 'daisyUI'],
    showcase: 'live demo',
  },
  {
    name: 'MERN + TypeScript authentification app with refresh token logic',
    description:
      'MERN stack authentification app with refresh token logic that uses TypeScript for type checking, JWT for generating and verifying tokens, Redux Toolkit for state management and daisyUI for UI.',
    projectURL: 'https://github.com/NikolaJohnny94/mern-stack-authentification',
    imgURL:
      'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: [
      'React.js',
      'Express.js',
      'Node.js',
      'MongoDB',
      'TypeScript',
      'Redux',
      'JWT',
      'daisyUI',
      'Docker',
    ],
    showcase: 'github',
  },
  {
    name: 'MERN and GraphQL CRUD Dashboard App',
    description:
      'Users dashboard app made with React.js, Context API, MUI, GraphQL, Node.js, Expres.js and MongoDB. Admin can view all or individual user, add, edit or delete user.',
    projectURL: 'https://github.com/NikolaJohnny94/mern-crud-graphql',
    imgURL:
      'https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: [
      'React.js',
      'Express.js',
      'Node.js',
      'MongoDB',
      'GraphQL',
      'MUI',
      'SASS',
    ],
    showcase: 'github',
  },
  {
    name: 'MEVN + TypeScript authentification app with refresh token logic and unit tests',
    description:
      'MEVN stack authentification app with refresh token logic that uses TypeScript for type checking, JWT for generating and verifying tokens, Pinia for state management, daisyUI for UI and Vitest for unit testing.',
    projectURL:
      'https://github.com/NikolaJohnny94/mevn-auth-with-refresh-token',
    imgURL:
      'https://images.unsplash.com/photo-1483817101829-339b08e8d83f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: [
      'Vue.js',
      'Express.js',
      'Node.js',
      'MongoDB',
      'TypeScript',
      'Pinia',
      'Vitest',
      'JWT',
      'daisyUI',
      'Docker',
    ],
    showcase: 'github',
  },
  {
    name: 'Vue 3 & GraphQL Rick and Morty Characters',
    description:
      'Rick and Morty characters app with pagination made with Vue 3 Composition API, Tailwind CSS, GraphQL and TypeScript.',
    projectURL: 'https://github.com/NikolaJohnny94/vue-graphql-rick-and-morty',
    imgURL:
      'https://images.unsplash.com/photo-1542831371-29b0f74f9713?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',

    technologies: ['Vue.js', 'GraphQL', 'Tailwind', 'TypeScript'],
    showcase: 'github',
  },
  {
    name: 'MEAN and Bootstrap 5 CRUD Dashboard App',
    description:
      'Users dashboard app made with Angular, Node.js, Express.js, MongoDB, Bootstrap 5 and TypeScript.Admin can view, add, edit or delete user and also search for users.',
    projectURL: 'https://github.com/NikolaJohnny94/mean-crud-web-app',
    imgURL:
      'https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Angular', 'Express.js', 'Node.js', 'MongoDB', 'Bootstrap'],
    showcase: 'github',
  },
  {
    name: 'MEVN and Quasar CRUD app with JWT Authentification',
    description:
      'Dashboard panel with authentification made with Vue.js 3, Quasar, Express.js, MongoDB, Pinia and JWT. User can read, create, edit and delete his todo items, toggle dark mode and delete own account.',
    projectURL:
      'https://github.com/NikolaJohnny94/mevn_app_with_authentification',
    imgURL:
      'https://images.unsplash.com/photo-1542903660-eedba2cda473?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: [
      'Vue.js',
      'Express.js',
      'Node.js',
      'MongoDB',
      'Quasar',
      'Pinia',
      'JWT',
      'Swagger',
    ],
    showcase: 'github',
  },
  {
    name: 'Nest.js and MongoDB CRUD API',
    description:
      'CRUD Rest API made with Nest.js that also contains Swagger documentation. Admin can view all or individual user, create new, update existing user, delete existing user (soft delete), and also there is a pagination.',
    projectURL: 'https://github.com/NikolaJohnny94/nest-mongo-employees-api',
    imgURL:
      'https://images.unsplash.com/photo-1619410283995-43d9134e7656?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Nest.js', 'Node.js', 'MongoDB', 'TypeScript', 'Swagger'],
    showcase: 'github',
  },
  {
    name: 'Express.js countries and cities API',
    description:
      'Populating DB with a script with data from CSV file. Authorization with JWT and protected routes logic with is implemented. Authorized user can search for countries and cities by any parameters. Based on selected country user can get two cities with the shortest and two cities with the longest distances calculated with haversine formula.',
    projectURL:
      'https://github.com/NikolaJohnny94/express-api-countries-and-cities',
    imgURL:
      'https://images.unsplash.com/photo-1516116216624-53e697fedbea?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Node.js', 'Express.js', 'MongoDB', 'Swagger', 'JWT'],
    showcase: 'github',
  },
  {
    name: 'React.js and FastAPI CRUD App',
    description:
      'Users dashboard app made with React.js, Python, FastAPI, MySQL, Redux, React-Bootstrap.Admin can view, add, edit or delete user and also search for users.',
    projectURL: 'https://github.com/NikolaJohnny94/react-fastapi-mysql',
    imgURL:
      'https://images.unsplash.com/photo-1521185496955-15097b20c5fe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['React.js', 'Bootstrap', 'Python', 'FastAPI', 'MySQL'],
    showcase: 'github',
  },
  {
    name: 'Digital Agency website made with WordPress and Elementor',
    description:
      'Digital Agency website made with WordPress and Elementor. Website have 4 pages: home, about, services and contact.',
    projectURL:
      'https://wordpress-demo.nikola-ivanovic-software-engineer.com/digitalagency',
    imgURL:
      'https://images.unsplash.com/photo-1607705703571-c5a8695f18f6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&w=500&q=60',
    technologies: ['WordPress', 'Elementor'],
    showcase: 'live demo',
  },
  {
    name: 'Next.js, Strapi.io, TypeScript and React-Bootstrap restaurants blog',
    description:
      'Restaurants blog made with Next.js, React-Bootstrap, TypeScript and fetching data fromt the API that is generated with Strapi.io. User can view all blogs, individual blog, reviews and reviewers pages and select blog by category',
    projectURL:
      'https://github.com/NikolaJohnny94/nextjs-typescript-restaurants-blog',
    imgURL:
      'https://images.unsplash.com/photo-1523800503107-5bc3ba2a6f81?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Next.js', 'Strapi.io', 'TypeScript', 'Bootstrap'],
    showcase: 'github',
  },
  {
    name: 'Nuxt.js 2, Strapi.io, TypeScript and BootstrapVue restaurants blog',
    description:
      'Restaurants blog made with Nuxt.js(staticly generated), BootstrapVue, TypeScript and fetching data fromt the API that is generated with Strapi.io headless CMS.On home page user can see all blogs, also user can see individual blog and select blog by category. User can see blog reviews and see reviewers profile pages.',
    projectURL: 'https://github.com/NikolaJohnny94/nuxt-restaurants-blog',
    imgURL:
      'https://images.unsplash.com/photo-1614741118887-7a4ee193a5fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Nuxt.js', 'Strapi.io', 'TypeScript', 'Bootstrap'],
    showcase: 'github',
  },
  {
    name: 'MERN & Bootstrap CRUD App',
    description:
      'Users dashboard app made with React.js, Node.js, Express.js, MongoDB, Redux and Bootstrap 5.Admin can view, add, edit or delete user and also search for users.',
    projectURL: 'https://github.com/NikolaJohnny94/mern-crud-web-app',
    imgURL:
      'https://images.unsplash.com/photo-1522252234503-e356532cafd5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: [
      'React.js',
      'Express.js',
      'Node.js',
      'MongoDB',
      'Redux',
      'Bootstrap',
    ],
    showcase: 'github',
  },
  {
    name: 'MEVN CRUD App',
    description:
      'Users dashboard app made with Vue.js, Node.js, Express.js, MongoDB, Vuex and Bootstrap 5.Admin can view, add, edit or delete user, search for users and export users in CSV files.',
    projectURL: 'https://github.com/NikolaJohnny94/mevn_crud_app_',
    imgURL:
      'https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: [
      'Vue.js',
      'Express.js',
      'Node.js',
      'MongoDB',
      'Vuex',
      'Bootstrap',
    ],
    showcase: 'github',
  },
  {
    name: 'React.js Rick & Morty Characters app',
    description:
      'Rick and Morty characters app with pagination and search characters field made with React.js, Bootstrap 5, Context API and CSS modules.',
    projectURL:
      'https://github.com/NikolaJohnny94/rick_and_morty_characters_app',
    imgURL:
      'https://images.unsplash.com/photo-1525373698358-041e3a460346?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['React.js', 'React-Bootstrap', 'Context'],
    showcase: 'github',
  },
  {
    name: 'Nuxt.js Breaking Bad app',
    description:
      "Breaking Bad and Better Call Soul staticly generated app with characters card's info.",
    projectURL: 'https://github.com/NikolaJohnny94/nuxt_breaking_bad_app_',
    imgURL:
      'https://images.unsplash.com/photo-1505238680356-667803448bb6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Nuxt.js', 'BootstrapVue', 'Vuex'],
    showcase: 'github',
  },
  {
    name: 'Python Web Scraper',
    description:
      'Python Web Scrapper made with bs4 that scrapes website and saves the data (url, h1, title, description) in CSV file using pandas.',
    projectURL:
      'https://github.com/NikolaJohnny94/basic_beautiful_soup_web_scraper_',
    imgURL:
      'https://images.unsplash.com/photo-1560762484-813fc97650a0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Python', 'Beautiful Soup 4', 'Pandas'],
    showcase: 'github',
  },
  {
    name: 'Vue.js, Flask-RESTful, MySQL and Bootstrap CRUD app',
    description:
      'Users dashboard app made with Vue.js, Python, Flask-RESTful, MySQL, Vuex and Bootstrap 5. Admin can view, add, edit or delete user, search for users and export users in CSV files.',
    projectURL: 'https://github.com/NikolaJohnny94/vue-flask-mysql-crud-app',
    imgURL:
      'https://images.unsplash.com/photo-1627399270231-7d36245355a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Vue.js', 'Vuex', 'Python', 'Flask', 'MySQL', 'Bootstrap'],
    showcase: 'github',
  },
  {
    name: 'Flask and MySQL CRUD app',
    description:
      "Users dashboard app made with Python, Flask, MySQL, Bootstrap 5 and Flask's Jinja templates. Admin can view, add, edit or delete user, search for users and export users in CSV files.",
    projectURL: 'https://github.com/NikolaJohnny94/flask-mysql-crud',
    imgURL:
      'https://images.unsplash.com/photo-1633356122544-f134324a6cee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Python', 'Flask', 'MySQL', 'Bootstrap'],
    showcase: 'github',
  },
  {
    name: 'Vue.js, Nest.js and Microsoft SQL Server CRUD App',
    description:
      'Users dashboard app made with Vue.js, Node.js, Nest.js, Microsoft SQL Server, Vuex and Bootstrap 5. Admin can view, add, edit or delete user, search for users and export users in CSV files.',
    projectURL: 'https://github.com/NikolaJohnny94/vue-nestjs-mssql',
    imgURL:
      'https://images.unsplash.com/photo-1509966756634-9c23dd6e6815?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: [
      'Vue.js',
      'Vuex',
      'Nest.js',
      'TypeScript',
      'Node.js',
      'MSSQL',
      'Bootstrap',
    ],
    showcase: 'github',
  },
  {
    name: 'Vue.js Spring Boot and MySQL CRUD app',
    description:
      'Users dashboard app made with Vue.js, Java, SpringBoot, Microsoft SQL Server, Vuex and Bootstrap 5. Admin can view, add, edit or delete user, search for users and export users in CSV files.',
    projectURL: 'https://github.com/NikolaJohnny94/vue-spring-mysql-crud',
    imgURL:
      'https://images.unsplash.com/photo-1607743386830-f198fbd7f9c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: [
      'Vue.js',
      'Java',
      'SpringBoot',
      'MySQL',
      'Vuex',
      'Bootstrap',
    ],
    showcase: 'github',
  },
  {
    name: 'Vue 2 Current weather app',
    description:
      'Weather app made with Vue.js 2. User can search for the city and get the weather data. User also have filters to display the metrics in Celsius or Fahrenheit digreees, or Kelvin, and also dark mode toggle switch.',
    projectURL: 'https://github.com/NikolaJohnny94/vue_current_weather_app_',
    imgURL:
      'https://images.unsplash.com/photo-1623520333087-62b8793e3d23?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    technologies: ['Vue.js', 'Vuex'],
    showcase: 'github',
  },
]

export const socialMediaIcons: SocialMediaIcon[] = [
  {
    name: 'GitHub',
    url: 'https://github.com/NikolaJohnny94',
    imgURL:
      'https://cdn.icon-icons.com/icons2/936/PNG/512/github-logo_icon-icons.com_73546.png',
    title: 'Follow me on GitHub',
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/nikola-ivanovi%C4%87-2b6a13179/',
    imgURL:
      'https://cdn.icon-icons.com/icons2/805/PNG/512/linkedin_icon-icons.com_65929.png',
    title: "Let's connect on LinkedIn",
  },
  {
    name: 'X',
    url: 'https://x.com/nikola8794pwd',
    imgURL:
      'https://cdn.icon-icons.com/icons2/4029/PNG/512/twitter_x_new_logo_x_icon_256077.png',
    title: 'Follow me on X',
  },
  {
    name: 'CodePen',
    url: 'https://codepen.io/NikolaJohnny/pens/public',
    imgURL:
      'https://cdn.icon-icons.com/icons2/1996/PNG/512/code_codepen_coding_development_program_programming_icon_123266.png',
    title: 'Follow me on CodePen',
  },
  {
    name: 'Gmail',
    url: `mailto:nikolaivanovic4business@gmail.com?subject=Impressed by your portfolio and interested in hiring you&body=
        Dear Nikola, %0D%0A%0D%0A
        I came across your portfolio website and I was impressed by your skills and projects. %0D%0A%0D%0A
        I would like to know if you are available for freelance or contract work, or if you are open to collaboration on some of the projects that I am working on. %0D%0A%0D%0A
        Please let me know if you have some time to chat and share your insights.%0D%0A%0D%0A
        Thank you for your time and attention.%0D%0A%0D%0A
        Sincerely,%0D%0A`,
    imgURL:
      'https://cdn.icon-icons.com/icons2/2631/PNG/512/gmail_new_logo_icon_159149.png',
    title: 'Send me an email',
  },
]

export const navLinks: NavLink[] = [
  {
    url: '/',
    text: 'Home',
    tooltipText: 'Home page',
  },
  {
    url: '/projects',
    text: 'Projects',
    tooltipText: 'See all my projects',
  },
  {
    url: '/contact',
    text: 'Contact',
    tooltipText: 'Contact me',
  },
  {
    url: '/files/cv/CV - Nikola Ivanovic.pdf',
    text: 'CV',
    downloadAttr: 'CV - Nikola Ivanovic.pdf',
    tooltipText: 'Download my CV',
  },
]

export const VIEW_ALL_SKILLS_BUTTON_TEXT = 'Check All My Skills'
export const VIEW_ALL_PROJECTS_BUTTON_TEXT = 'Check All My Projects'
